import {Link} from "react-router-dom";
import React from "react";

const Header = () => {
    return (
        <>
        <div className="th-menu-wrapper onepage-nav">
            <div className="th-menu-area text-center">
                <button className="th-menu-toggle">
                    <i className="fal fa-times"/>
                </button>
                <div className="mobile-logo">
                    <Link to="/">
                    <img src="/assets/img/logo2.svg" alt="Tourm"/>
                    </Link>
            </div>
            <div className="th-mobile-menu">
                    <ul>

                        <li>
                            {/*<a href="about.html">About Us</a>*/}
                            <li><Link to="/About">About Us</Link></li>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Destination</a>
                            <ul className="sub-menu">
                                <li>
                                    <a href="destination.html">Destination</a>
                                </li>
                                <li>
                                    <a href="destination-details.html">Destination Details</a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Service</a>
                            <ul className="sub-menu">
                                <li>
                                    {/*<a href="service.html">Services</a>*/}

                                </li>
                                <li>
                                    <a href="service-details.html">Service Details</a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Activities</a>
                            <ul className="sub-menu">
                                <li>
                                    <a href="activities.html">activities</a>
                                </li>
                                <li>
                                    <a href="activities-details.html">activities Details</a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Pages</a>
                            <ul className="sub-menu">
                                <li className="menu-item-has-children">
                                    <a href="#">Shop</a>
                                    <ul className="sub-menu">
                                        <li>
                                            <a href="shop.html">Shop</a>
                                        </li>
                                        <li>
                                            <a href="shop-details.html">Shop Details</a>
                                        </li>
                                        <li>
                                            <a href="cart.html">Cart Page</a>
                                        </li>
                                        <li>
                                            <a href="checkout.html">Checkout</a>
                                        </li>
                                        <li>
                                            <a href="wishlist.html">Wishlist</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="gallery.html">Gallery</a>
                                </li>
                                <li>
                                    <a href="tour.html">Our Tour</a>
                                </li>
                                <li>
                                    <a href="tour-details.html">Tour Details</a>
                                </li>
                                <li>
                                    <a href="resort.html">Resort page</a>
                                </li>
                                <li>
                                    <a href="resort-details.html">Resort Details</a>
                                </li>
                                <li>
                                    <a href="tour-details.html">Tour Details</a>
                                </li>
                                <li>
                                    <a href="tour-guide.html">Tour Guider</a>
                                </li>
                                <li>
                                    <a href="tour-guider-details.html">Tour Guider Details</a>
                                </li>
                                <li>
                                    <a href="faq.html">Faq Page</a>
                                </li>
                                <li>
                                    <a href="price.html">Price Package</a>
                                </li>
                                <li>
                                    <a href="error.html">Error Page</a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Blog</a>
                            <ul className="sub-menu">
                                <li>
                                    <a href="blog.html">Blog</a>
                                </li>
                                <li>
                                    <a href="blog-details.html">Blog Details</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="contact.html">Contact us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    <header className="th-header header-layout1">
        <div className="header-top">
            <div className="container th-container">
                <div className="row justify-content-center justify-content-xl-between align-items-center">
                    <div className="col-auto d-none d-md-block">
                        <div className="header-links">
                            <ul>
                                <li className="d-none d-xl-inline-block">
                                    <i className="fa-sharp fa-regular fa-location-dot"/>{" "}
                                    <span>71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ</span>
                                </li>
                                <li className="d-none d-xl-inline-block">
                                    <i className="fa-regular fa-clock"/>{" "}
                                    <span>Sun to Saturday: 24 X 7</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="header-right">
                            <div className="currency-menu">
                                <select className="form-select nice-select">
                                    <option selected="">language</option>
                                    <option>CNY</option>
                                    <option>EUR</option>
                                    <option>AUD</option>
                                </select>
                            </div>
                            <div className="header-links">
                                <ul>
                                    <li className="d-none d-md-inline-block">
                                        <a href="faq.html">FAQ</a>
                                    </li>
                                    <li className="d-none d-md-inline-block">
                                        <a href="contact.html">Support</a>
                                    </li>
                                    <li>
                                        <a href="#login-form" className="popup-content">
                                            Sign In / Register
                                            <i className="fa-regular fa-user"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="sticky-wrapper">
            <div className="menu-area">
                <div className="container th-container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="header-logo">
                                <Link to="/">
                                    <img src="/assets/img/Gold_and_Black_Plane_Travel_Creative_Logo-removebg-preview.png" alt="Tourm"/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-auto me-xl-auto">
                            <nav className="main-menu d-none d-xl-inline-block">
                                <ul>
                                    <li className="menu-item-has-children mega-menu-wrap">
                                        <Link className="active" to="/">
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <li><Link to="/About">About Us</Link></li>
                                    </li>
                                    {/*<li className="menu-item-has-children">*/}
                                    {/*    <a href="#">Destination</a>*/}
                                    {/*    <ul className="sub-menu">*/}
                                    {/*        <li>*/}
                                    {/*            <a href="destination.html">Destination</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="destination-details.html">*/}
                                    {/*                Destination Details*/}
                                    {/*            </a>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                    <li className="menu-item-has-children">
                                        <a href="#">Service</a>
                                        <ul className="sub-menu">
                                            <li>
                                                <li><Link to="/Service">Services</Link></li>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <a href="service-details.html">Service Details</a>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </li>
                                    {/*<li className="menu-item-has-children">*/}
                                    {/*    <a href="#">Activities</a>*/}
                                    {/*    <ul className="sub-menu">*/}
                                    {/*        <li>*/}
                                    {/*            <a href="activities.html">activities</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="activities-details.html">activities Details</a>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                    {/*<li className="menu-item-has-children">*/}
                                    {/*    <a href="#">Pages</a>*/}
                                    {/*    <ul className="sub-menu">*/}
                                    {/*        <li className="menu-item-has-children">*/}
                                    {/*            <a href="#">Shop</a>*/}
                                    {/*            <ul className="sub-menu">*/}
                                    {/*                <li>*/}
                                    {/*                    <a href="shop.html">Shop</a>*/}
                                    {/*                </li>*/}
                                    {/*                <li>*/}
                                    {/*                    <a href="shop-details.html">Shop Details</a>*/}
                                    {/*                </li>*/}
                                    {/*                <li>*/}
                                    {/*                    <a href="cart.html">Cart Page</a>*/}
                                    {/*                </li>*/}
                                    {/*                <li>*/}
                                    {/*                    <a href="checkout.html">Checkout</a>*/}
                                    {/*                </li>*/}
                                    {/*                <li>*/}
                                    {/*                    <a href="wishlist.html">Wishlist</a>*/}
                                    {/*                </li>*/}
                                    {/*            </ul>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="gallery.html">Gallery</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="tour.html">Our Tour</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="tour-details.html">Tour Details</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="resort.html">Resort page</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="resort-details.html">Resort Details</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="tour-details.html">Tour Details</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="tour-guide.html">Tour Guider</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="tour-guider-details.html">*/}
                                    {/*                Tour Guider Details*/}
                                    {/*            </a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="faq.html">Faq Page</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="price.html">Price Package</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="error.html">Error Page</a>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                    {/*<li className="menu-item-has-children">*/}
                                    {/*    <a href="#">Blog</a>*/}
                                    {/*    <ul className="sub-menu">*/}
                                    {/*        <li>*/}
                                    {/*            <a href="blog.html">Blog</a>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <a href="blog-details.html">Blog Details</a>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </nav>
                            <button
                                type="button"
                                className="th-menu-toggle d-block d-xl-none"
                            >
                                <i className="far fa-bars"/>
                            </button>
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <div className="header-button">
                                <a href="contact.html" className="th-btn style3 th-icon">
                                    Book Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="logo-bg" data-mask-src="assets/img/logo_bg_mask.png"/>
            </div>
        </div>
    </header>
        </>
)
}
export default Header
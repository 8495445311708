import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './FlightResult.css'; // Import the CSS file for styles
import Header from "../Header";

const FlightResult = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Access the flights data directly from the location state
    const flights = location.state?.flights || []; // Use an empty array if flights are not found
    console.log("Flight Result Data:", flights);
    const traceId = flights.Response?.TraceId;
    localStorage.setItem("traceId", traceId);

    // Function to navigate to flight details page and save only the resultIndex to localStorage
    const handleFlightDetailsClick = (flight, index) => {
        // Navigate to the flight details page and pass the flight data
        navigate('/flight-details', { state: { flight, index } });
    };

    return (
	    <>
        <Header/>

        <div className="flight-result-container">
            <h2>Flight Results</h2>
            {flights.Response && (
                <div className="response-container">
                    {/*<h3>Response</h3>*/}
                    {/*<div>Response Status: {flights.Response.ResponseStatus}</div>*/}
                    {/*<div>Error: {flights.Response.Error?.ErrorMessage}</div>*/}
                    {/*<div>TraceId: {flights.Response.TraceId}</div>*/}
                    {/*<div>Origin: {flights.Response.Origin}</div>*/}
                    {/*<div>Destination: {flights.Response.Destination}</div>*/}

                    {flights.Response.Results && flights.Response.Results.map((resultSet, resultSetIndex) => (
                        <div key={resultSetIndex} className="results-set-container">
                            {/*<h3>Results Set {resultSetIndex + 1}</h3>*/}
                            {resultSet.map((result, resultIndex) => (
                                <div key={resultIndex} className="flight-card">
                                    {/* Airline Logo, Name, and Refund Status */}
                                    <div className="airline-info">
                                        <img
                                            src={result.Airline?.LogoUrl || '/assets/img/channels4_profile.jpg'}
                                            alt={result.Segments?.[0]?.[0]?.Airline?.AirlineName || 'Airline'}
                                            className="airline-logo"
                                        />
                                        <div>
                                            <h5 >
                                                {result.Segments?.[0]?.[0]?.Airline?.AirlineName || 'Airline'}
                                            </h5>
                                            <span className={`refund-tag ${result.IsRefundable ? 'refundable' : 'non-refundable'}`}>
                                    {result.IsRefundable ? 'Refundable' : 'Non-refundable'}
                                </span>
                                        </div>
                                    </div>

                                    {/* Departure Time and City */}
                                    <div className="flight-info">
                                        <span>{result.Segments?.[0]?.[0]?.Origin?.DepTime || '00:00'}</span><br />
                                        <span>{result.Segments?.[0]?.[0]?.Origin?.Airport?.CityName || 'City'}</span>
                                    </div>

                                    {/* Flight Time with Dashed Line and Icons */}
                                    <div className="flight-time">
                                        <span className="duration">{result.Segments?.[0]?.[0]?.Duration + ' ' +'minutes'|| '0 minutes'}</span>
                                        <div className="dashed-line"></div>
                                        <span className="non-stop">{result.Segments?.[0]?.[0]?.StopOver ? 'Connecting' : 'Non-stop'}</span>
                                    </div>

                                    {/* Arrival Time and City */}
                                    <div className="flight-info">
                                        <span>{result.Segments?.[0]?.[0]?.Destination?.ArrTime || '00:00'}</span><br />
                                        <span>{result.Segments?.[0]?.[0]?.Destination?.Airport?.CityName || 'City'}</span>
                                    </div>

                                    {/* Price and Class Details */}
                                    <div className="price-info">
                            <span className="cheapest-badge">
                                {result.IsCheapest ? 'Cheapest' : ''}
                            </span><br />
                                        <p className="price">
                                            {result.Fare?.BaseFare || 'N/A'}
                                        </p>
                                        <p className="business-class">
                                            {result.Segments?.[0]?.[0]?.Airline?.FareClass || 'Class'}
                                        </p>
                                        <button
                                            className="flight-details-btn"
                                            onClick={() => handleFlightDetailsClick(result, resultIndex)}
                                        >
                                            Flight Details
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}

        </div>
	    </>
    );
};

export default FlightResult;

import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import './FlightDetails.css';
import {getFareQuote, getFareRule} from "../Service/FlightService";
import Header from "../../Header";

const FlightDetails = () => {
    const location = useLocation();
    const flight = location.state?.flight || {}; // Safely retrieve flight data
    console.log(flight);
    localStorage.setItem('resultIndex', flight.ResultIndex)

    useEffect(() => {
        async function fetchFareDetails() {
            try {
                // Fetch Fare Rule
                const fareRuleResponse = await getFareRule();
                console.log('Fare Rule Response:', fareRuleResponse);

                // Fetch Fare Quote
                const fareQuoteResponse = await getFareQuote();
                console.log('Fare Quote Response:', fareQuoteResponse);
            } catch (error) {
                console.error('Error fetching fare details:', error);
            }
        }

        fetchFareDetails();
    }, []);





    // State for passengers
    const [passengers, setPassengers] = useState([{ title: '', firstName: '', lastName: '', gender: '', phone: '', email: '', dob: '', pan: '', passport: '' }]);

    // Function to handle input changes for passengers
    const handlePassengerChange = (index, event) => {
        const { name, value } = event.target;
        const newPassengers = [...passengers];
        newPassengers[index][name] = value;
        setPassengers(newPassengers);
    };

    // Function to add more passengers
    const addPassenger = () => {
        setPassengers([...passengers, { title: '', firstName: '', lastName: '', gender: '', phone: '', email: '', dob: '', pan: '', passport: '' }]);
    };

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Passenger Details:", passengers);
    };

    // Safely access flight details
    const origin = flight.Segments[0][0].Origin.Airport.CityCode || 'Unknown';
    const fromCity = flight.Segments[0][0].Origin.Airport.CityName || 'City Name';
    const departureTime = flight.Segments[0][0].Origin.DepTime || '12:30 PM';
    const departureAirportName = flight.Segments[0][0].Origin.Airport.AirportName || 'Airport Name';
    const destination = flight.Segments[0][0].Destination.Airport.CityCode || 'Unknown';
    const toCity = flight.Segments[0][0].Destination.Airport.CityName || 'City Name';
    const arrivalTime = flight.Segments[0][0].Destination.ArrTime || '02:30 PM';
    const arrivalDate = flight.Segments[0][0].Destination.Airport.AirportName || 'Airport Name';
    const duration = flight.Segments[0][0].Duration + ' minutes' || '120 mins';
    const flightNumber = flight.Segments[0][0].Airline.FlightNumber || 'AI123';
    const airline = flight.Segments[0][0].Airline.AirlineName || 'Air India';
    const cabinBaggage = flight.Segments[0][0].CabinBaggage || '7 kg';
    const checkinBaggage = flight.Segments[0][0].Baggage || '20 kg';
    const flightClass = flight.Segments[0][0].CabinClass || 'Economy';
    const publishedFare = flight.Fare.PublishedFare;
    const tax = flight.Fare.Tax;
    const YQTax = flight.Fare.YQTax;
    const isPanRequired = flight.IsPanRequiredAtBook || false;
    const isPassportRequired = flight.IsPassportRequiredAtBook || false;


    return (
        <>
	    <Header/>
            <div className="container">
                <div className="mx-auto text-center mb-5" style={{ maxWidth: '900px', marginTop: '15px' }}>
                    <h5 className="section-title px-3">Check Out</h5>
                    <h1 className="mb-0">Add Passenger Details</h1>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        {/* Flight Details Section */}
                        <div className="col-md-8 col-sm-12 mb-3">
                            <div className="details-box p-3 bg-white border rounded mb-3">
                                <h4>Flight Details</h4>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p style={{fontSize: '30px'}}>{origin}</p>
                                        <p style={{fontSize: '18px'}}>{fromCity}</p>
                                        <p className="text-muted">{departureTime} <br/> {departureAirportName}</p>
                                    </div>
                                    <span>--------------------</span>
                                    <i className="fas fa-plane" style={{fontSize: '35px'}}></i>
                                    <span>--------------------</span>
                                    <div>
                                        <p style={{fontSize: '30px'}}>{destination}</p>
                                        <p style={{fontSize: '18px'}}>{toCity}</p>
                                        <p className="text-muted">{arrivalTime} <br/> {arrivalDate}</p>
                                    </div>
                                </div>
                                <table className="table mt-3">
                                    <tbody>
                                    <tr>
                                        <td>Duration</td>
                                        <td>{duration}</td>
                                    </tr>
                                    <tr>
                                        <td>Flight Number</td>
                                        <td>{flightNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Airline</td>
                                        <td>{airline}</td>
                                    </tr>
                                    <tr>
                                        <td>Cabin Baggage</td>
                                        <td>{cabinBaggage}</td>
                                    </tr>
                                    <tr>
                                        <td>Check-in Baggage</td>
                                        <td>{checkinBaggage}</td>
                                    </tr>
                                    <tr>
                                        <td>Class</td>
                                        <td>{flightClass}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* Passenger Form Section */}
                            <div className="passenger-form p-3 bg-white border rounded">
                                <h4>Passenger Details</h4>
                                <form onSubmit={handleSubmit}>
                                    {passengers.map((passenger, index) => (
                                        <div className="row mb-3" key={index}>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor={`title-${index}`}>Title</label>
                                                    <select
                                                        id={`title-${index}`}
                                                        name="title"
                                                        value={passenger.title}
                                                        onChange={(event) => handlePassengerChange(index, event)}
                                                        className="form-control"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Mr">Mr</option>
                                                        <option value="Ms">Ms</option>
                                                        <option value="Mrs">Mrs</option>
                                                        <option value="Dr">Dr</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor={`firstname-${index}`}>First Name</label>
                                                    <input
                                                        type="text"
                                                        id={`firstname-${index}`}
                                                        name="firstName"
                                                        value={passenger.firstName}
                                                        onChange={(event) => handlePassengerChange(index, event)}
                                                        className="form-control"
                                                        placeholder="Enter first name"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor={`lastname-${index}`}>Last Name</label>
                                                    <input
                                                        type="text"
                                                        id={`lastname-${index}`}
                                                        name="lastName"
                                                        value={passenger.lastName}
                                                        onChange={(event) => handlePassengerChange(index, event)}
                                                        className="form-control"
                                                        placeholder="Enter last name"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor={`gender-${index}`}>Gender</label>
                                                    <select
                                                        id={`gender-${index}`}
                                                        name="gender"
                                                        value={passenger.gender}
                                                        onChange={(event) => handlePassengerChange(index, event)}
                                                        className="form-control"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Add PAN and Passport fields if required */}
                                            {isPanRequired && (
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor={`pan-${index}`}>PAN Number</label>
                                                        <input
                                                            type="text"
                                                            id={`pan-${index}`}
                                                            name="pan"
                                                            value={passenger.pan}
                                                            onChange={(event) => handlePassengerChange(index, event)}
                                                            className="form-control"
                                                            placeholder="Enter PAN Number"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {isPassportRequired && (
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor={`passport-${index}`}>Passport Number</label>
                                                        <input
                                                            type="text"
                                                            id={`passport-${index}`}
                                                            name="passport"
                                                            value={passenger.passport}
                                                            onChange={(event) => handlePassengerChange(index, event)}
                                                            className="form-control"
                                                            placeholder="Enter Passport Number"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor={`phone-${index}`}>Phone Number</label>
                                                    <input
                                                        type="text"
                                                        id={`phone-${index}`}
                                                        name="phone"
                                                        value={passenger.phone}
                                                        onChange={(event) => handlePassengerChange(index, event)}
                                                        className="form-control"
                                                        placeholder="Enter phone number"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor={`email-${index}`}>Email</label>
                                                    <input
                                                        type="email"
                                                        id={`email-${index}`}
                                                        name="email"
                                                        value={passenger.email}
                                                        onChange={(event) => handlePassengerChange(index, event)}
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor={`dob-${index}`}>Date of Birth</label>
                                                    <input
                                                        type="date"
                                                        id={`dob-${index}`}
                                                        name="dob"
                                                        value={passenger.dob}
                                                        onChange={(event) => handlePassengerChange(index, event)}
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <button type="button" className="btn btn-primary" onClick={addPassenger}>Add More
                                        Passengers
                                    </button>
                                    <button type="submit" className="btn btn-success mt-3">Submit</button>
                                </form>
                            </div>
                        </div>

                        {/* Fare Details Section */}
                        <div className="col-md-4 col-sm-12 mb-3">
                            <div className="fare-box p-3 bg-light border rounded">
                                <h4>Fare Details</h4>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td>Base Fare</td>
                                        <td><i className="fa-solid fa-indian-rupee-sign"></i> {publishedFare}</td>
                                    </tr>
                                    <tr>
                                        <td>Tax</td>
                                        <td><i className="fa-solid fa-indian-rupee-sign"></i> {tax}</td>
                                    </tr>
                                    <tr>
                                        <td>Convenience Fee</td>
                                        <td><i className="fa-solid fa-indian-rupee-sign"></i> {YQTax}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Fare</td>
                                        <td><i
                                            className="fa-solid fa-indian-rupee-sign"></i> {Number(publishedFare) + Number(tax) + Number(YQTax)}
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan="2" className="text-center">
                                            <button className="btn btn-success mt-3">Proceed to Payment</button>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlightDetails;

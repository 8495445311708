export async function authenticateUser() {
    try {
        const response = await fetch('https://api.farefuelsholidays.com/api/auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('API Response:', data);

        const tokenId = data.tokenId;
        if (tokenId) {
            // Store token in localStorage
            localStorage.setItem('tokenId', tokenId);
            console.log('Token saved to localStorage:', tokenId);
        } else {
            console.warn('TokenId not found in response');
        }
    } catch (error) {
        console.error('Error during authentication:', error);
    }
}

import React, { useState, useEffect, useRef } from "react";

const CustomSelect = ({ options, value, onChange, label }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Filter options based on search term
    const filteredOptions = options.filter(option =>
        option.cityCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.cityName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Handle selecting an option
    const handleSelect = (option) => {
        onChange(option.cityCode); // Pass the cityCode as value
        setSearchTerm(""); // Clear the search term
        setIsOpen(false); // Close the dropdown
    };

    // Handle clicks outside of the dropdown
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="custom-select" ref={dropdownRef}>
            <label>{label}</label>
            <div className="select-container" onClick={() => setIsOpen(prev => !prev)}>
                <div className="select-input">{value || "Select..."}</div>
            </div>
            {isOpen && (
                <div className="dropdown">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="options">
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option) => (
                                <div
                                    key={option.id}
                                    className="option"
                                    onClick={() => handleSelect(option)}
                                >
                                    {option.cityCode} - {option.cityName}
                                </div>
                            ))
                        ) : (
                            <div className="option">No options found</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomSelect;

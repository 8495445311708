import logo from './logo.svg';
import './App.css';
import {BrowserRouter as  Router, Route, Routes} from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import NavBar from "./Components/Navbar";
import {authenticateUser} from "./Components/Authentication/Auth";
import {useEffect} from "react";
import FlightResult from "./Components/BookingForm/FlightResult";
import FlightDetails from "./Components/BookingForm/FlightDetails/FlightDetails";
import Service from "./Components/Service";

function App() {

    useEffect(() => {
        authenticateUser();
    }, []);
  return (
      <Router>
        <div>
          {/* Define routes here */}
          {/*<NavBar />*/}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/flight-results" element={<FlightResult />} />
            <Route path="/flight-details" element={<FlightDetails />} />
            <Route path="/about" element={<About />} />
              <Route path="/service" element={<Service />} />
              <Route path="/destination.html"  />

          </Routes>

        </div>
      </Router>
  );
}

export default App;

const Server_BASE_URL = process.env.REACT_APP_SERVER_BASE;



function getLocalStorageData() {
    return {
        EndUserIp: '192.168.10.10', // Fixed IP address
        TokenId: localStorage.getItem('tokenId') || '', // Retrieve TokenId from localStorage
        TraceId: localStorage.getItem('traceId') || '', // Retrieve TraceId from localStorage
        ResultIndex: localStorage.getItem('resultIndex') || '', // Retrieve ResultIndex from localStorage
    };
}



async function sendRequest(endpoint, method = 'GET', data = null) {
    try {
        const options = {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (data && method === 'POST') {
            options.body = JSON.stringify(data);
        }

        const response = await fetch(`${Server_BASE_URL}${endpoint}`, options);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error(`Error making ${method} request:`, error);
        throw error;
    }
}

export async function searchFlights(data) {
    return sendRequest('/api/send', 'POST', data);
}

export async function getAirport() {
    return sendRequest('/api/app2/airports/getall', 'GET');
}

export async function getFareRule(){
    const data = getLocalStorageData();
    return sendRequest('/api/farerule', 'POST', data);
}

export async function getFareQuote(){
    const data = getLocalStorageData();
    return sendRequest('/api/farequote', 'POST', data);
}

import {useEffect} from "react";
import Footer from "./Footer";
import Header from "./Header";
import {Link} from "react-router-dom";

const About = () => {
    useEffect(() => {
        // Dynamically load external scripts
        const loadScript = (src) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        };

        const scripts = [
            '/assets/js/vendor/jquery-3.6.0.min.js',
            '/assets/js/swiper-bundle.min.js',
            '/assets/js/bootstrap.min.js',
            '/assets/js/jquery.magnific-popup.min.js',
            '/assets/js/jquery.counterup.min.js',
            '/assets/js/jquery-ui.min.js',
            '/assets/js/imagesloaded.pkgd.min.js',
            '/assets/js/isotope.pkgd.min.js',
            '/assets/js/gsap.min.js',
            '/assets/js/circle-progress.js',
            '/assets/js/matter.min.js',
            '/assets/js/matterjs-custom.js',
            '/assets/js/nice-select.min.js',
            '/assets/js/main.js',
        ];

        // Load all scripts
        scripts.forEach((script) => loadScript(script));
    }, []);



    return (
        <>
            {/* Mirrored from html.themeholy.com/tourm/demo/about.html by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 20 Sep 2024 17:13:48 GMT */}
            <meta charSet="utf-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <title>
                Tourm - Travel &amp; Tour Booking Agency HTML Template - About Tourm
            </title>
            <meta name="author" content="Tourm" />
            <meta
                name="description"
                content="Tourm - Travel & Tour Booking Agency HTML Template "
            />
            <meta
                name="keywords"
                content="Tourm - Travel & Tour Booking Agency HTML Template "
            />
            <meta name="robots" content="INDEX,FOLLOW" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no"
            />
            <link
                rel="apple-touch-icon"
                sizes="57x57"
                href="/assets/img/favicons/apple-icon-57x57.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="60x60"
                href="/assets/img/favicons/apple-icon-60x60.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="72x72"
                href="/assets/img/favicons/apple-icon-72x72.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="76x76"
                href="/assets/img/favicons/apple-icon-76x76.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/assets/img/favicons/apple-icon-114x114.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/assets/img/favicons/apple-icon-120x120.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/assets/img/favicons/apple-icon-144x144.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/assets/img/favicons/apple-icon-152x152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/assets/img/favicons/apple-icon-180x180.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/assets/img/favicons/android-icon-192x192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/assets/img/favicons/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/assets/img/favicons/favicon-96x96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/assets/img/favicons/favicon-16x16.png"
            />
            <link rel="manifest" href="/assets/img/favicons/manifest.json" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-TileImage"
                content="assets/img/favicons/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff" />
            <link rel="preconnect" href="https://fonts.googleapis.com/" />
            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="" />
            <link rel="preconnect" href="https://fonts.googleapis.com/" />
            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="" />
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montez&display=swap"
                rel="stylesheet"
            />
            <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
            <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
            <link rel="stylesheet" href="assets/css/magnific-popup.min.css" />
            <link rel="stylesheet" href="assets/css/swiper-bundle.min.css" />
            <link rel="stylesheet" href="assets/css/style.css" />
            <div className="magic-cursor relative z-10">
                <div className="cursor" />
                <div className="cursor-follower" />
            </div>
            <div className="color-scheme-wrap active">
                <button className="switchIcon">
                    <i className="fa-solid fa-palette" />
                </button>
                <h4 className="color-scheme-wrap-title">
                    <i className="far fa-palette" />
                    STYLE SWITCHER
                </h4>
                <div className="color-switch-btns">
                    <button data-color="#684DF4">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#086ad8">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#FC3737">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#8a2be2">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#104CBA">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#ffbf4f">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#323F7C">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#0e2bc5">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#F79C53">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#6957af">
                        <i className="fa-solid fa-droplet" />
                    </button>
                </div>
                <a
                    href="https://themeforest.net/search/themeholy"
                    className="th-btn text-center"
                >
                    <i className="fa fa-shopping-cart me-2" /> Purchase
                </a>
            </div>
          {/*  <div id="preloader" className="preloader">*/}
          {/*      <button className="th-btn preloaderCls">Cancel Preloader</button>*/}
          {/*      <div className="preloader-inner">*/}
          {/*          <img src="/assets/img/logo3.svg" alt="" />*/}
          {/*      </div>*/}
          {/*      <div id="loader" className="th-preloader">*/}
          {/*          <div className="animation-preloader">*/}
          {/*              <div className="txt-loading">*/}
          {/*<span preloader-text="T" className="characters">*/}
          {/*  T{" "}*/}
          {/*</span>*/}
          {/*                  <span preloader-text="O" className="characters">*/}
          {/*  O{" "}*/}
          {/*</span>*/}
          {/*                  <span preloader-text="U" className="characters">*/}
          {/*  U{" "}*/}
          {/*</span>*/}
          {/*                  <span preloader-text="R" className="characters">*/}
          {/*  R{" "}*/}
          {/*</span>*/}
          {/*                  <span preloader-text="M" className="characters">*/}
          {/*  M*/}
          {/*</span>*/}
          {/*              </div>*/}
          {/*          </div>*/}
          {/*      </div>*/}
          {/*  </div>*/}
            <div className="sidemenu-wrapper sidemenu-info">
                <div className="sidemenu-content">
                    <button className="closeButton sideMenuCls">
                        <i className="far fa-times" />
                    </button>
                    <div className="widget">
                        <div className="th-widget-about">
                            <div className="about-logo">
                                <a href="home-travel.html">
                                    <img src="/assets/img/logo2.svg" alt="Tourm" />
                                </a>
                            </div>
                            <p className="about-text">
                                Rapidiously myocardinate cross-platform intellectual capital model.
                                Appropriately create interactive infrastructures
                            </p>
                            <div className="th-social">
                                <a href="https://www.facebook.com/">
                                    <i className="fab fa-facebook-f" />
                                </a>{" "}
                                <a href="https://www.twitter.com/">
                                    <i className="fab fa-twitter" />
                                </a>{" "}
                                <a href="https://www.linkedin.com/">
                                    <i className="fab fa-linkedin-in" />
                                </a>{" "}
                                <a href="https://www.whatsapp.com/">
                                    <i className="fab fa-whatsapp" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="widget">
                        <h3 className="widget_title">Recent Posts</h3>
                        <div className="recent-post-wrap">
                            <div className="recent-post">
                                <div className="media-img">
                                    <a href="blog-details.html">
                                        <img
                                            src="/assets/img/blog/recent-post-1-1.jpg"
                                            alt="Blog Image"
                                        />
                                    </a>
                                </div>
                                <div className="media-body">
                                    <div className="recent-post-meta">
                                        <a href="blog.html">
                                            <i className="far fa-calendar" />
                                            24 Jun , 2024
                                        </a>
                                    </div>
                                    <h4 className="post-title">
                                        <a className="text-inherit" href="blog-details.html">
                                            Where Vision Meets Concrete Reality
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div className="recent-post">
                                <div className="media-img">
                                    <a href="blog-details.html">
                                        <img
                                            src="/assets/img/blog/recent-post-1-2.jpg"
                                            alt="Blog Image"
                                        />
                                    </a>
                                </div>
                                <div className="media-body">
                                    <div className="recent-post-meta">
                                        <a href="blog.html">
                                            <i className="far fa-calendar" />
                                            22 Jun , 2024
                                        </a>
                                    </div>
                                    <h4 className="post-title">
                                        <a className="text-inherit" href="blog-details.html">
                                            Raising the Bar in Construction.
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget">
                        <h3 className="widget_title">Get In Touch</h3>
                        <div className="th-widget-contact">
                            <div className="info-box_text">
                                <div className="icon">
                                    <img src="/assets/img/icon/phone.svg" alt="img" />
                                </div>
                                <div className="details">
                                    <p>
                                        <a href="tel:+01234567890" className="info-box_link">
                                            +01 234 567 890
                                        </a>
                                    </p>
                                    <p>
                                        <a href="tel:+09876543210" className="info-box_link">
                                            +09 876 543 210
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="info-box_text">
                                <div className="icon">
                                    <img src="/assets/img/icon/envelope.svg" alt="img" />
                                </div>
                                <div className="details">
                                    <p>
                                        <a href="mailto:mailinfo00@tourm.com" className="info-box_link">
                                            mailinfo00@tourm.com
                                        </a>
                                    </p>
                                    <p>
                                        <a href="mailto:support24@tourm.com" className="info-box_link">
                                            support24@tourm.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="info-box_text">
                                <div className="icon">
                                    <img
                                        src="/assets/img/icon/location-dot.svg"
                                        alt="img"
                                    />
                                </div>
                                <div className="details">
                                    <p>789 Inner Lane, Holy park, California, USA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="popup-search-box">
                <button className="searchClose">
                    <i className="fal fa-times" />
                </button>
                <form action="#">
                    <input type="text" placeholder="What are you looking for?" />{" "}
                    <button type="submit">
                        <i className="fal fa-search" />
                    </button>
                </form>
            </div>
            <Header/>
            <div
                className="breadcumb-wrapper"
                data-bg-src="assets/img/bg/breadcumb-bg.jpg"
            >
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">About Farefuels</h1>
                        <ul className="breadcumb-menu">

                            <li><Link className="active" to="/">
                                Home
                            </Link>
                            </li>

                            <li>About Farefuels</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div
                className="about-area position-relative overflow-hidden overflow-hidden space"
                id="about-sec"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="img-box3">
                                <div className="img1">
                                    <img
                                        src="/assets/img/normal/about_3_1.jpg"
                                        alt="About"
                                    />
                                </div>
                                <div className="img2">
                                    <img
                                        src="/assets/img/normal/about_3_2.jpg"
                                        alt="About"
                                    />
                                </div>
                                <div className="img3 movingX">
                                    <img
                                        src="/assets/img/normal/about_3_3.jpg"
                                        alt="About"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="ps-xl-4">
                                <div className="title-area mb-20">
                                    <span className="sub-title style1">Welcome To Tourm</span>
                                    <h2 className="sec-title mb-20 pe-xl-5 me-xl-5 heading">
                                        We are world reputeted travel agency
                                    </h2>
                                </div>
                                <p className="pe-xl-5">
                                    There are many variations of passages of available but the
                                    majority have suffered alteration in some form, by injected hum
                                    randomised words.
                                </p>
                                <p className="mb-30 pe-xl-5">
                                    Leiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                    nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                    sunt in culpa qui officia deserunt.
                                </p>
                                <div className="about-item-wrap">
                                    <div className="about-item style2">
                                        <div className="about-item_img">
                                            <img
                                                src="/assets/img/icon/about_1_1.svg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="about-item_centent">
                                            <h5 className="box-title">Exclusive Trip</h5>
                                            <p className="about-item_text">
                                                There are many variations of passages of available but the
                                                majority.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="about-item style2">
                                        <div className="about-item_img">
                                            <img
                                                src="/assets/img/icon/about_1_2.svg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="about-item_centent">
                                            <h5 className="box-title">Safety First Always</h5>
                                            <p className="about-item_text">
                                                There are many variations of passages of available but the
                                                majority.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="about-item style2">
                                        <div className="about-item_img">
                                            <img
                                                src="/assets/img/icon/about_1_3.svg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="about-item_centent">
                                            <h5 className="box-title">Professional Guide</h5>
                                            <p className="about-item_text">
                                                There are many variations of passages of available but the
                                                majority.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-35">
                                    <a href="contact.html" className="th-btn style3 th-icon">
                                        Contact With Us
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="shape-mockup movingX d-none d-xxl-block"
                    data-top="4%"
                    data-left="2%"
                >
                    <img src="/assets/img/shape/shape_2_1.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup jump d-none d-xxl-block"
                    data-top="28%"
                    data-right="5%"
                >
                    <img src="/assets/img/shape/shape_2_2.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup spin d-none d-xxl-block"
                    data-bottom="18%"
                    data-left="2%"
                >
                    <img src="/assets/img/shape/shape_2_3.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup movixgX d-none d-xxl-block"
                    data-bottom="18%"
                    data-right="2%"
                >
                    <img src="/assets/img/shape/shape_2_4.png" alt="shape" />
                </div>
            </div>
            <section
                className="position-relative overflow-hidden space-bottom"
                id="destination-sec"
            >
                <div className="container">
                    <div className="title-area text-center">
                        <span className="sub-title">Services We Offer</span>
                        <h2 className="sec-title">Our Amazing services</h2>
                    </div>
                    <div className="row gy-4 gx-4">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_1.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Photo Shoot</a>
                                    </h3>
                                    <p className="destination-text">20 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_2.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Tour Guide</a>
                                    </h3>
                                    <p className="destination-text">22 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_3.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Cozy Event</a>
                                    </h3>
                                    <p className="destination-text">23 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_4.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Interesting Rest</a>
                                    </h3>
                                    <p className="destination-text">24 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_5.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Kayaking</a>
                                    </h3>
                                    <p className="destination-text">25 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_6.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Safe Flight</a>
                                    </h3>
                                    <p className="destination-text">26 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_7.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Entertainment</a>
                                    </h3>
                                    <p className="destination-text">27 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="destination-item th-ani">
                                <div className="destination-item_img global-img">
                                    <img
                                        src="/assets/img/destination/destination_4_8.jpg"
                                        alt="image"
                                    />
                                </div>
                                <div className="destination-content">
                                    <h3 className="box-title">
                                        <a href="service-details.html">Delicisious Food</a>
                                    </h3>
                                    <p className="destination-text">28 Listing</p>
                                    <a href="contact.html" className="th-btn style4 th-icon">
                                        Book Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="elements-sec bg-white overflow-hidden">
                <div className="container-fluid">
                    <div className="tags-container relative" />
                </div>
            </div>
            <section
                className="team-area3 position-relative bg-top-center space"
                data-bg-src="assets/img/bg/team_bg_2.jpg"
            >
                <div className="container z-index-common">
                    <div className="title-area text-center">
                        <span className="sub-title">Meet with Guide</span>
                        <h2 className="sec-title">Meet with Tour Guide</h2>
                    </div>
                    <div className="slider-area">
                        <div
                            className="swiper th-slider teamSlider3 has-shadow"
                            id="teamSlider3"
                            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"}}}'
                        >
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="th-team team-grid">
                                        <div className="team-img">
                                            <img
                                                src="/assets/img/team/team_img_1.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-img2">
                                            <img
                                                src="/assets/img/team/team_1_1.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Michel Smith</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://youtube.com/">
                                                        <i className="fab fa-youtube" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-grid">
                                        <div className="team-img">
                                            <img
                                                src="/assets/img/team/team_img_2.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-img2">
                                            <img
                                                src="/assets/img/team/team_1_2.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Janny Willson</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://youtube.com/">
                                                        <i className="fab fa-youtube" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-grid">
                                        <div className="team-img">
                                            <img
                                                src="/assets/img/team/team_img_3.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-img2">
                                            <img
                                                src="/assets/img/team/team_1_3.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Jacob Jones</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://youtube.com/">
                                                        <i className="fab fa-youtube" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-grid">
                                        <div className="team-img">
                                            <img
                                                src="/assets/img/team/team_img_1.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-img2">
                                            <img
                                                src="/assets/img/team/team_1_4.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Maria Prova</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://youtube.com/">
                                                        <i className="fab fa-youtube" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-grid">
                                        <div className="team-img">
                                            <img
                                                src="/assets/img/team/team_img_2.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-img2">
                                            <img
                                                src="/assets/img/team/team_1_5.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Rebeka Maliha</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://youtube.com/">
                                                        <i className="fab fa-youtube" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-grid">
                                        <div className="team-img">
                                            <img
                                                src="/assets/img/team/team_img_3.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-img2">
                                            <img
                                                src="/assets/img/team/team_1_6.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Alif Mahmud</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://youtube.com/">
                                                        <i className="fab fa-youtube" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-grid">
                                        <div className="team-img">
                                            <img
                                                src="/assets/img/team/team_img_1.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-img2">
                                            <img
                                                src="/assets/img/team/team_1_3.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Guy Hawkins</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://youtube.com/">
                                                        <i className="fab fa-youtube" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="th-team team-grid">
                                        <div className="team-img">
                                            <img
                                                src="/assets/img/team/team_img_2.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-img2">
                                            <img
                                                src="/assets/img/team/team_1_4.jpg"
                                                alt="Team"
                                            />
                                        </div>
                                        <div className="team-content">
                                            <div className="media-body">
                                                <h3 className="box-title">
                                                    <a href="tour-guider-details.html">Jenny Wilson</a>
                                                </h3>
                                                <span className="team-desig">Tourist Guide</span>
                                                <div className="th-social">
                                                    <a target="_blank" href="https://facebook.com/">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://twitter.com/">
                                                        <i className="fab fa-twitter" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://linkedin.com/">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://youtube.com/">
                                                        <i className="fab fa-youtube" />
                                                    </a>{" "}
                                                    <a target="_blank" href="https://instagram.com/">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-pagination" />
                        </div>
                        <button
                            data-slider-prev="#teamSlider3"
                            className="slider-arrow slider-prev"
                        >
                            <img src="/assets/img/icon/right-arrow2.svg" alt="" />
                        </button>{" "}
                        <button
                            data-slider-next="#teamSlider3"
                            className="slider-arrow slider-next"
                        >
                            <img src="/assets/img/icon/left-arrow2.svg" alt="" />
                        </button>
                    </div>
                </div>
            </section>
            <section className="testi-area overflow-hidden space-bottom" id="testi-sec">
                <div className="container-fluid p-0">
                    <div className="title-area mb-20 text-center">
                        <span className="sub-title">Testimonial</span>
                        <h2 className="sec-title">What Client Say About us</h2>
                    </div>
                    <div className="slider-area">
                        <div
                            className="swiper th-slider testiSlider1 has-shadow"
                            id="testiSlider1"
                            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"767":{"slidesPerView":"2","centeredSlides":"true"},"992":{"slidesPerView":"2","centeredSlides":"true"},"1200":{"slidesPerView":"2","centeredSlides":"true"},"1400":{"slidesPerView":"3","centeredSlides":"true"}}}'
                        >
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Maria Doe</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            “A home that perfectly blends sustainability with luxury until
                                            I discovered Ecoland Residence. From the moment I stepped into
                                            this community, I knew it was where I wanted to live. The
                                            commitment to eco-friendly living”
                                        </p>
                                        <div className="testi-card-quote">
                                            <img
                                                src="/assets/img/icon/testi-quote.svg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_2.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Andrew Simon</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            “The home boasts sleek, contemporary architecture with clean
                                            lines and expansive windows, allowing natural light to flood
                                            the interiors It incorporates passive design principles”
                                        </p>
                                        <div className="testi-card-quote">
                                            <img
                                                src="/assets/img/icon/testi-quote.svg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Alex Jordan</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            “Solar panels adorn the roof, harnessing renewable energy to
                                            power the home and even feed excess electricity back into the
                                            grid. High-performance insulation and triple-glazed”
                                        </p>
                                        <div className="testi-card-quote">
                                            <img
                                                src="/assets/img/icon/testi-quote.svg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_2.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Maria Doe</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            A sophisticated rainwater harvesting system collects and
                                            filters rainwater for irrigation and non-potable uses,
                                            reducing reliance on municipal water sources. Greywater
                                            systems
                                        </p>
                                        <div className="testi-card-quote">
                                            <img
                                                src="/assets/img/icon/testi-quote.svg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Angelina Rose</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            Throughout the interior, eco-friendly materials like reclaimed
                                            wood, bamboo flooring, and recycled glass countertops create a
                                            luxurious yet sustainable ambiance.
                                        </p>
                                        <div className="testi-card-quote">
                                            <img
                                                src="/assets/img/icon/testi-quote.svg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Maria Doe</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            “A home that perfectly blends sustainability with luxury until
                                            I discovered Ecoland Residence. From the moment I stepped into
                                            this community, I knew it was where I wanted to live. The
                                            commitment to eco-friendly living”
                                        </p>
                                        <div className="testi-card-quote">
                                            <img
                                                src="/assets/img/icon/testi-quote.svg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_2.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Andrew Simon</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            A sophisticated rainwater harvesting system collects and
                                            filters rainwater for irrigation and non-potable uses,
                                            reducing reliance on municipal water sources. Greywater
                                            systems
                                        </p>
                                        <div className="testi-card-quote">
                                            <img
                                                src="/assets/img/icon/testi-quote.svg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testi-card">
                                        <div className="testi-card_wrapper">
                                            <div className="testi-card_profile">
                                                <div className="testi-card_avater">
                                                    <img
                                                        src="/assets/img/testimonial/testi_1_1.jpg"
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="box-title">Alex Jordan</h3>
                                                    <span className="testi-card_desig">Traveller</span>
                                                </div>
                                            </div>
                                            <div className="testi-card_review">
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />{" "}
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                        <p className="testi-card_text">
                                            Throughout the interior, eco-friendly materials like reclaimed
                                            wood, bamboo flooring, and recycled glass countertops create a
                                            luxurious yet sustainable ambiance.
                                        </p>
                                        <div className="testi-card-quote">
                                            <img
                                                src="/assets/img/icon/testi-quote.svg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-pagination" />
                        </div>
                    </div>
                </div>
                <div
                    className="shape-mockup d-none d-xl-block"
                    data-bottom="-2%"
                    data-right="0%"
                >
                    <img src="/assets/img/shape/line2.png" alt="shape" />
                </div>
                <div
                    className="shape-mockup movingX d-none d-xl-block"
                    data-top="30%"
                    data-left="5%"
                >
                    <img src="/assets/img/shape/shape_7.png" alt="shape" />
                </div>
            </section>
            <div className="brand-area overflow-hidden">
                <div className="container th-container">
                    <div
                        className="swiper th-slider brandSlider1"
                        id="brandSlider1"
                        data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"3"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"6"},"1400":{"slidesPerView":"8"}}}'
                    >
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_1.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_1.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_2.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_2.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_3.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_3.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_4.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_4.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_5.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_5.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_6.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_6.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_7.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_7.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_8.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_8.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_4.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_4.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_3.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_3.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_2.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_2.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="brand-box">
                                    <a href="#">
                                        <img
                                            className="original"
                                            src="/assets/img/brand/brand_1_1.svg"
                                            alt="Brand Logo"
                                        />{" "}
                                        <img
                                            className="gray"
                                            src="/assets/img/brand/brand_1_1.svg"
                                            alt="Brand Logo"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sidebar-gallery-area space">
                <div className="container-fluid">
                    <div className="slider-area">
                        <div
                            className="swiper th-slider has-shadow"
                            data-slider-options='{"centeredSlides":"true","breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"},"1300":{"slidesPerView":"4"}}}'
                        >
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_1.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_2.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_3.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_4.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_5.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_1.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_2.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_3.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_4.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_5.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="gallery-thumb style2 global-img">
                                        <img
                                            src="/assets/img/gallery/gallery_4_2.jpg"
                                            alt="Gallery Image"
                                        />{" "}
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/"
                                            className="gallery-btn"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer/>
            <div className="scroll-top">
                <svg
                    className="progress-circle svg-content"
                    width="100%"
                    height="100%"
                    viewBox="-1 -1 102 102"
                >
                    <path
                        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                        style={{
                            transition: "stroke-dashoffset 10ms linear 0s",
                            strokeDasharray: "307.919, 307.919",
                            strokeDashoffset: "307.919"
                        }}
                    />
                </svg>
            </div>
            <div id="login-form" className="popup-login-register mfp-hide">
                <ul className="nav" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-menu"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="false"
                        >
                            Login
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-menu active"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="true"
                        >
                            Register
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                    >
                        <h3 className="box-title mb-30">Sign in to your account</h3>
                        <div className="th-login-form">
                            <form
                                action="https://html.themeholy.com/tourm/demo/mail.php"
                                method="POST"
                                className="login-form ajax-contact"
                            >
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label>Username or email</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            required="required"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label>Password</label>{" "}
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="pasword"
                                            id="pasword"
                                            required="required"
                                        />
                                    </div>
                                    <div className="form-btn mb-20 col-12">
                                        <button className="th-btn btn-fw th-radius2">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                                <div id="forgot_url">
                                    <a href="my-account.html">Forgot password?</a>
                                </div>
                                <p className="form-messages mb-0 mt-3" />
                            </form>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade active show"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                    >
                        <h3 className="th-form-title mb-30">Sign in to your account</h3>
                        <form
                            action="https://html.themeholy.com/tourm/demo/mail.php"
                            method="POST"
                            className="login-form ajax-contact"
                        >
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Username*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="usename"
                                        id="usename"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label>First name*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="firstname"
                                        id="firstname"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label>Last name*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="lastname"
                                        id="lastname"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="new_email">Your email*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="new_email"
                                        id="new_email"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="new_email_confirm">Confirm email*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="new_email_confirm"
                                        id="new_email_confirm"
                                        required="required"
                                    />
                                </div>
                                <div className="statement">
              <span className="register-notes">
                A password will be emailed to you.
              </span>
                                </div>
                                <div className="form-btn mt-20 col-12">
                                    <button className="th-btn btn-fw th-radius2">Sign up</button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3" />
                        </form>
                    </div>
                </div>
            </div>
            {/* Mirrored from html.themeholy.com/tourm/demo/about.html by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 20 Sep 2024 17:13:52 GMT */}
        </>

    )
}

export default About;
import {useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";

const Contact = () => {

    useEffect(() => {
        // Dynamically load external scripts
        const loadScript = (src) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        };

        const scripts = [
            '/assets/js/vendor/jquery-3.6.0.min.js',
            '/assets/js/swiper-bundle.min.js',
            '/assets/js/bootstrap.min.js',
            '/assets/js/jquery.magnific-popup.min.js',
            '/assets/js/jquery.counterup.min.js',
            '/assets/js/jquery-ui.min.js',
            '/assets/js/imagesloaded.pkgd.min.js',
            '/assets/js/isotope.pkgd.min.js',
            '/assets/js/gsap.min.js',
            '/assets/js/circle-progress.js',
            '/assets/js/matter.min.js',
            '/assets/js/matterjs-custom.js',
            '/assets/js/nice-select.min.js',
            '/assets/js/main.js',
        ];

        // Load all scripts
        scripts.forEach((script) => loadScript(script));
    }, []);


    return (
        <>
            {/* Mirrored from html.themeholy.com/tourm/demo/contact.html by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 20 Sep 2024 17:14:21 GMT */}
            <meta charSet="utf-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <title>
                Tourm - Travel &amp; Tour Booking Agency HTML Template - Contact Us
            </title>
            <meta name="author" content="Tourm" />
            <meta
                name="description"
                content="Tourm - Travel & Tour Booking Agency HTML Template "
            />
            <meta
                name="keywords"
                content="Tourm - Travel & Tour Booking Agency HTML Template "
            />
            <meta name="robots" content="INDEX,FOLLOW" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no"
            />
            <link
                rel="apple-touch-icon"
                sizes="57x57"
                href="/assets/img/favicons/apple-icon-57x57.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="60x60"
                href="/assets/img/favicons/apple-icon-60x60.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="72x72"
                href="/assets/img/favicons/apple-icon-72x72.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="76x76"
                href="/assets/img/favicons/apple-icon-76x76.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/assets/img/favicons/apple-icon-114x114.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/assets/img/favicons/apple-icon-120x120.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/assets/img/favicons/apple-icon-144x144.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/assets/img/favicons/apple-icon-152x152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/assets/img/favicons/apple-icon-180x180.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/assets/img/favicons/android-icon-192x192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/assets/img/favicons/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/assets/img/favicons/favicon-96x96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/assets/img/favicons/favicon-16x16.png"
            />
            <link rel="manifest" href="/assets/img/favicons/manifest.json" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-TileImage"
                content="assets/img/favicons/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff" />
            <link rel="preconnect" href="https://fonts.googleapis.com/" />
            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="" />
            <link rel="preconnect" href="https://fonts.googleapis.com/" />
            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="" />
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montez&display=swap"
                rel="stylesheet"
            />
            <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
            <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
            <link rel="stylesheet" href="assets/css/magnific-popup.min.css" />
            <link rel="stylesheet" href="assets/css/swiper-bundle.min.css" />
            <link rel="stylesheet" href="assets/css/style.css" />
            <div className="magic-cursor relative z-10">
                <div className="cursor" />
                <div className="cursor-follower" />
            </div>
            <div className="color-scheme-wrap active">
                <button className="switchIcon">
                    <i className="fa-solid fa-palette" />
                </button>
                <h4 className="color-scheme-wrap-title">
                    <i className="far fa-palette" />
                    STYLE SWITCHER
                </h4>
                <div className="color-switch-btns">
                    <button data-color="#684DF4">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#086ad8">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#FC3737">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#8a2be2">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#104CBA">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#ffbf4f">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#323F7C">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#0e2bc5">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#F79C53">
                        <i className="fa-solid fa-droplet" />
                    </button>{" "}
                    <button data-color="#6957af">
                        <i className="fa-solid fa-droplet" />
                    </button>
                </div>
                <a
                    href="https://themeforest.net/search/themeholy"
                    className="th-btn text-center"
                >
                    <i className="fa fa-shopping-cart me-2" /> Purchase
                </a>
            </div>
          {/*  <div id="preloader" className="preloader">*/}
          {/*      <button className="th-btn preloaderCls">Cancel Preloader</button>*/}
          {/*      <div className="preloader-inner">*/}
          {/*          <img src="/assets/img/logo3.svg" alt="" />*/}
          {/*      </div>*/}
          {/*      <div id="loader" className="th-preloader">*/}
          {/*          <div className="animation-preloader">*/}
          {/*              <div className="txt-loading">*/}
          {/*<span preloader-text="T" className="characters">*/}
          {/*  T{" "}*/}
          {/*</span>*/}
          {/*                  <span preloader-text="O" className="characters">*/}
          {/*  O{" "}*/}
          {/*</span>*/}
          {/*                  <span preloader-text="U" className="characters">*/}
          {/*  U{" "}*/}
          {/*</span>*/}
          {/*                  <span preloader-text="R" className="characters">*/}
          {/*  R{" "}*/}
          {/*</span>*/}
          {/*                  <span preloader-text="M" className="characters">*/}
          {/*  M*/}
          {/*</span>*/}
          {/*              </div>*/}
          {/*          </div>*/}
          {/*      </div>*/}
          {/*  </div>*/}
            <div className="sidemenu-wrapper sidemenu-info">
                <div className="sidemenu-content">
                    <button className="closeButton sideMenuCls">
                        <i className="far fa-times" />
                    </button>
                    <div className="widget">
                        <div className="th-widget-about">
                            <div className="about-logo">
                                <a href="home-travel.html">
                                    <img src="/assets/img/logo2.svg" alt="Tourm" />
                                </a>
                            </div>
                            <p className="about-text">
                                Rapidiously myocardinate cross-platform intellectual capital model.
                                Appropriately create interactive infrastructures
                            </p>
                            <div className="th-social">
                                <a href="https://www.facebook.com/">
                                    <i className="fab fa-facebook-f" />
                                </a>{" "}
                                <a href="https://www.twitter.com/">
                                    <i className="fab fa-twitter" />
                                </a>{" "}
                                <a href="https://www.linkedin.com/">
                                    <i className="fab fa-linkedin-in" />
                                </a>{" "}
                                <a href="https://www.whatsapp.com/">
                                    <i className="fab fa-whatsapp" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="widget">
                        <h3 className="widget_title">Recent Posts</h3>
                        <div className="recent-post-wrap">
                            <div className="recent-post">
                                <div className="media-img">
                                    <a href="blog-details.html">
                                        <img
                                            src="/assets/img/blog/recent-post-1-1.jpg"
                                            alt="Blog Image"
                                        />
                                    </a>
                                </div>
                                <div className="media-body">
                                    <div className="recent-post-meta">
                                        <a href="blog.html">
                                            <i className="far fa-calendar" />
                                            24 Jun , 2024
                                        </a>
                                    </div>
                                    <h4 className="post-title">
                                        <a className="text-inherit" href="blog-details.html">
                                            Where Vision Meets Concrete Reality
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div className="recent-post">
                                <div className="media-img">
                                    <a href="blog-details.html">
                                        <img
                                            src="/assets/img/blog/recent-post-1-2.jpg"
                                            alt="Blog Image"
                                        />
                                    </a>
                                </div>
                                <div className="media-body">
                                    <div className="recent-post-meta">
                                        <a href="blog.html">
                                            <i className="far fa-calendar" />
                                            22 Jun , 2024
                                        </a>
                                    </div>
                                    <h4 className="post-title">
                                        <a className="text-inherit" href="blog-details.html">
                                            Raising the Bar in Construction.
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget">
                        <h3 className="widget_title">Get In Touch</h3>
                        <div className="th-widget-contact">
                            <div className="info-box_text">
                                <div className="icon">
                                    <img src="/assets/img/icon/phone.svg" alt="img" />
                                </div>
                                <div className="details">
                                    <p>
                                        <a href="tel:+01234567890" className="info-box_link">
                                            +01 234 567 890
                                        </a>
                                    </p>
                                    <p>
                                        <a href="tel:+09876543210" className="info-box_link">
                                            +09 876 543 210
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="info-box_text">
                                <div className="icon">
                                    <img src="/assets/img/icon/envelope.svg" alt="img" />
                                </div>
                                <div className="details">
                                    <p>
                                        <a href="mailto:mailinfo00@tourm.com" className="info-box_link">
                                            mailinfo00@tourm.com
                                        </a>
                                    </p>
                                    <p>
                                        <a href="mailto:support24@tourm.com" className="info-box_link">
                                            support24@tourm.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="info-box_text">
                                <div className="icon">
                                    <img
                                        src="/assets/img/icon/location-dot.svg"
                                        alt="img"
                                    />
                                </div>
                                <div className="details">
                                    <p>789 Inner Lane, Holy park, California, USA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="popup-search-box">
                <button className="searchClose">
                    <i className="fal fa-times" />
                </button>
                <form action="#">
                    <input type="text" placeholder="What are you looking for?" />{" "}
                    <button type="submit">
                        <i className="fal fa-search" />
                    </button>
                </form>
            </div>
            <div className="th-menu-wrapper onepage-nav">
                <div className="th-menu-area text-center">
                    <button className="th-menu-toggle">
                        <i className="fal fa-times" />
                    </button>
                    <div className="mobile-logo">
                        <a href="home-travel.html">
                            <img src="/assets/img/logo2.svg" alt="Tourm" />
                        </a>
                    </div>
                    <div className="th-mobile-menu">
                        <ul>
                            <li className="menu-item-has-children mega-menu-wrap">
                                <a className="active" href="home-travel.html">
                                    Home
                                </a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="home-travel.html">Home Travel</a>
                                    </li>
                                    <li>
                                        <a href="home-tour.html">Home Tour</a>
                                    </li>
                                    <li>
                                        <a href="home-agency.html">Home Agency</a>
                                    </li>
                                    <li>
                                        <a href="home-yacht.html">Home Yacht</a>
                                    </li>
                                    <li>
                                        <a href="home-beach.html">Home Beach</a>
                                    </li>
                                    <li>
                                        <a href="home-resort.html">Home Resort</a>
                                    </li>
                                    <li>
                                        <a href="home-countryside-hotel.html">Home Countryside Hotel</a>
                                    </li>
                                    <li>
                                        <a href="home-forest.html">Home Forest</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="about.html">About Us</a>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Destination</a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="destination.html">Destination</a>
                                    </li>
                                    <li>
                                        <a href="destination-details.html">Destination Details</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Service</a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="service.html">Services</a>
                                    </li>
                                    <li>
                                        <a href="service-details.html">Service Details</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Activities</a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="activities.html">activities</a>
                                    </li>
                                    <li>
                                        <a href="activities-details.html">activities Details</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Pages</a>
                                <ul className="sub-menu">
                                    <li className="menu-item-has-children">
                                        <a href="#">Shop</a>
                                        <ul className="sub-menu">
                                            <li>
                                                <a href="shop.html">Shop</a>
                                            </li>
                                            <li>
                                                <a href="shop-details.html">Shop Details</a>
                                            </li>
                                            <li>
                                                <a href="cart.html">Cart Page</a>
                                            </li>
                                            <li>
                                                <a href="checkout.html">Checkout</a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html">Wishlist</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="gallery.html">Gallery</a>
                                    </li>
                                    <li>
                                        <a href="tour.html">Our Tour</a>
                                    </li>
                                    <li>
                                        <a href="tour-details.html">Tour Details</a>
                                    </li>
                                    <li>
                                        <a href="resort.html">Resort page</a>
                                    </li>
                                    <li>
                                        <a href="resort-details.html">Resort Details</a>
                                    </li>
                                    <li>
                                        <a href="tour-details.html">Tour Details</a>
                                    </li>
                                    <li>
                                        <a href="tour-guide.html">Tour Guider</a>
                                    </li>
                                    <li>
                                        <a href="tour-guider-details.html">Tour Guider Details</a>
                                    </li>
                                    <li>
                                        <a href="faq.html">Faq Page</a>
                                    </li>
                                    <li>
                                        <a href="price.html">Price Package</a>
                                    </li>
                                    <li>
                                        <a href="error.html">Error Page</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Blog</a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="blog.html">Blog</a>
                                    </li>
                                    <li>
                                        <a href="blog-details.html">Blog Details</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="contact.html">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
          {/*  <header className="th-header header-layout1">*/}
          {/*      <div className="header-top">*/}
          {/*          <div className="container th-container">*/}
          {/*              <div className="row justify-content-center justify-content-xl-between align-items-center">*/}
          {/*                  <div className="col-auto d-none d-md-block">*/}
          {/*                      <div className="header-links">*/}
          {/*                          <ul>*/}
          {/*                              <li className="d-none d-xl-inline-block">*/}
          {/*                                  <i className="fa-sharp fa-regular fa-location-dot" />{" "}*/}
          {/*                                  <span>45 New Eskaton Road, Austria</span>*/}
          {/*                              </li>*/}
          {/*                              <li className="d-none d-xl-inline-block">*/}
          {/*                                  <i className="fa-regular fa-clock" />{" "}*/}
          {/*                                  <span>Sun to Friday: 8.00 am - 7.00 pm</span>*/}
          {/*                              </li>*/}
          {/*                          </ul>*/}
          {/*                      </div>*/}
          {/*                  </div>*/}
          {/*                  <div className="col-auto">*/}
          {/*                      <div className="header-right">*/}
          {/*                          <div className="currency-menu">*/}
          {/*                              <select className="form-select nice-select">*/}
          {/*                                  <option selected="">language</option>*/}
          {/*                                  <option>CNY</option>*/}
          {/*                                  <option>EUR</option>*/}
          {/*                                  <option>AUD</option>*/}
          {/*                              </select>*/}
          {/*                          </div>*/}
          {/*                          <div className="header-links">*/}
          {/*                              <ul>*/}
          {/*                                  <li className="d-none d-md-inline-block">*/}
          {/*                                      <a href="faq.html">FAQ</a>*/}
          {/*                                  </li>*/}
          {/*                                  <li className="d-none d-md-inline-block">*/}
          {/*                                      <a href="contact.html">Support</a>*/}
          {/*                                  </li>*/}
          {/*                                  <li>*/}
          {/*                                      <a href="#login-form" className="popup-content">*/}
          {/*                                          Sign In / Register*/}
          {/*                                          <i className="fa-regular fa-user" />*/}
          {/*                                      </a>*/}
          {/*                                  </li>*/}
          {/*                              </ul>*/}
          {/*                          </div>*/}
          {/*                      </div>*/}
          {/*                  </div>*/}
          {/*              </div>*/}
          {/*          </div>*/}
          {/*      </div>*/}
          {/*      <div className="sticky-wrapper">*/}
          {/*          <div className="menu-area">*/}
          {/*              <div className="container th-container">*/}
          {/*                  <div className="row align-items-center justify-content-between">*/}
          {/*                      <div className="col-auto">*/}
          {/*                          <div className="header-logo">*/}
          {/*                              <a href="home-travel.html">*/}
          {/*                                  <img src="/assets/img/logo.svg" alt="Tourm" />*/}
          {/*                              </a>*/}
          {/*                          </div>*/}
          {/*                      </div>*/}
          {/*                      <div className="col-auto me-xl-auto">*/}
          {/*                          <nav className="main-menu d-none d-xl-inline-block">*/}
          {/*                              <ul>*/}
          {/*                                  <li className="menu-item-has-children mega-menu-wrap">*/}
          {/*                                      <a className="active" href="home-travel.html">*/}
          {/*                                          Home*/}
          {/*                                      </a>*/}
          {/*                                      <ul className="mega-menu mega-menu-content">*/}
          {/*                                          <li>*/}
          {/*                                              <div className="container">*/}
          {/*                                                  <div className="row gy-4">*/}
          {/*                                                      <div className="col-lg-3">*/}
          {/*                                                          <div className="mega-menu-box">*/}
          {/*                                                              <div className="mega-menu-img">*/}
          {/*                                                                  <img*/}
          {/*                                                                      src="/assets/img/pages/home-travel.jpg"*/}
          {/*                                                                      alt="Home One"*/}
          {/*                                                                  />*/}
          {/*                                                                  <div className="btn-wrap">*/}
          {/*                                                                      <a*/}
          {/*                                                                          target="_blank"*/}
          {/*                                                                          href="index.html"*/}
          {/*                                                                          className="th-btn"*/}
          {/*                                                                      >*/}
          {/*                                                                          View Demo*/}
          {/*                                                                      </a>*/}
          {/*                                                                  </div>*/}
          {/*                                                              </div>*/}
          {/*                                                              <h3 className="mega-menu-title">*/}
          {/*                                                                  <a href="index.html">*/}
          {/*                                                                      <span>01.</span>Home Travel*/}
          {/*                                                                  </a>*/}
          {/*                                                              </h3>*/}
          {/*                                                          </div>*/}
          {/*                                                      </div>*/}
          {/*                                                      <div className="col-lg-3">*/}
          {/*                                                          <div className="mega-menu-box">*/}
          {/*                                                              <div className="mega-menu-img">*/}
          {/*                                                                  <img*/}
          {/*                                                                      src="/assets/img/pages/home-tour.jpg"*/}
          {/*                                                                      alt="Home Two"*/}
          {/*                                                                  />*/}
          {/*                                                                  <div className="btn-wrap">*/}
          {/*                                                                      <a*/}
          {/*                                                                          target="_blank"*/}
          {/*                                                                          href="home-tour.html"*/}
          {/*                                                                          className="th-btn"*/}
          {/*                                                                      >*/}
          {/*                                                                          View Demo*/}
          {/*                                                                      </a>*/}
          {/*                                                                  </div>*/}
          {/*                                                              </div>*/}
          {/*                                                              <h3 className="mega-menu-title">*/}
          {/*                                                                  <a href="home-tour.html">*/}
          {/*                                                                      <span>02.</span>Home Tour*/}
          {/*                                                                  </a>*/}
          {/*                                                              </h3>*/}
          {/*                                                          </div>*/}
          {/*                                                      </div>*/}
          {/*                                                      <div className="col-lg-3">*/}
          {/*                                                          <div className="mega-menu-box">*/}
          {/*                                                              <div className="mega-menu-img">*/}
          {/*                                                                  <img*/}
          {/*                                                                      src="/assets/img/pages/home-agency.jpg"*/}
          {/*                                                                      alt="Home Three"*/}
          {/*                                                                  />*/}
          {/*                                                                  <div className="btn-wrap">*/}
          {/*                                                                      <a*/}
          {/*                                                                          target="_blank"*/}
          {/*                                                                          href="home-agency.html"*/}
          {/*                                                                          className="th-btn"*/}
          {/*                                                                      >*/}
          {/*                                                                          View Demo*/}
          {/*                                                                      </a>*/}
          {/*                                                                  </div>*/}
          {/*                                                              </div>*/}
          {/*                                                              <h3 className="mega-menu-title">*/}
          {/*                                                                  <a href="home-agency.html">*/}
          {/*                                                                      <span>03.</span>Home Agency*/}
          {/*                                                                  </a>*/}
          {/*                                                              </h3>*/}
          {/*                                                          </div>*/}
          {/*                                                      </div>*/}
          {/*                                                      <div className="col-lg-3">*/}
          {/*                                                          <div className="mega-menu-box">*/}
          {/*                                                              <div className="mega-menu-img">*/}
          {/*                                                                  <img*/}
          {/*                                                                      src="/assets/img/pages/home-yacht.jpg"*/}
          {/*                                                                      alt="Home Four"*/}
          {/*                                                                  />*/}
          {/*                                                                  <div className="btn-wrap">*/}
          {/*                                                                      <a*/}
          {/*                                                                          target="_blank"*/}
          {/*                                                                          href="home-yacht.html"*/}
          {/*                                                                          className="th-btn"*/}
          {/*                                                                      >*/}
          {/*                                                                          View Demo*/}
          {/*                                                                      </a>*/}
          {/*                                                                  </div>*/}
          {/*                                                              </div>*/}
          {/*                                                              <h3 className="mega-menu-title">*/}
          {/*                                                                  <a href="home-yacht.html">*/}
          {/*                                                                      <span>04.</span>Home Yacht*/}
          {/*                                                                  </a>*/}
          {/*                                                              </h3>*/}
          {/*                                                          </div>*/}
          {/*                                                      </div>*/}
          {/*                                                      <div className="col-lg-3">*/}
          {/*                                                          <div className="mega-menu-box">*/}
          {/*                                                              <div className="mega-menu-img">*/}
          {/*                                                                  <img*/}
          {/*                                                                      src="/assets/img/pages/home-beach.jpg"*/}
          {/*                                                                      alt="Home Five"*/}
          {/*                                                                  />*/}
          {/*                                                                  <div className="btn-wrap">*/}
          {/*                                                                      <a*/}
          {/*                                                                          target="_blank"*/}
          {/*                                                                          href="home-beach.html"*/}
          {/*                                                                          className="th-btn"*/}
          {/*                                                                      >*/}
          {/*                                                                          View Demo*/}
          {/*                                                                      </a>*/}
          {/*                                                                  </div>*/}
          {/*                                                              </div>*/}
          {/*                                                              <h3 className="mega-menu-title">*/}
          {/*                                                                  <a href="home-beach.html">*/}
          {/*                                                                      <span>05.</span>Home Beach*/}
          {/*                                                                  </a>*/}
          {/*                                                              </h3>*/}
          {/*                                                          </div>*/}
          {/*                                                      </div>*/}
          {/*                                                      <div className="col-lg-3">*/}
          {/*                                                          <div className="mega-menu-box">*/}
          {/*                                                              <div className="mega-menu-img">*/}
          {/*                                                                  <img*/}
          {/*                                                                      src="/assets/img/pages/home-resort.jpg"*/}
          {/*                                                                      alt="Home Six"*/}
          {/*                                                                  />*/}
          {/*                                                                  <div className="btn-wrap">*/}
          {/*                                                                      <a*/}
          {/*                                                                          target="_blank"*/}
          {/*                                                                          href="home-resort.html"*/}
          {/*                                                                          className="th-btn"*/}
          {/*                                                                      >*/}
          {/*                                                                          View Demo*/}
          {/*                                                                      </a>*/}
          {/*                                                                  </div>*/}
          {/*                                                              </div>*/}
          {/*                                                              <h3 className="mega-menu-title">*/}
          {/*                                                                  <a href="home-resort.html">*/}
          {/*                                                                      <span>06.</span>Home resort*/}
          {/*                                                                  </a>*/}
          {/*                                                              </h3>*/}
          {/*                                                          </div>*/}
          {/*                                                      </div>*/}
          {/*                                                      <div className="col-lg-3">*/}
          {/*                                                          <div className="mega-menu-box">*/}
          {/*                                                              <div className="mega-menu-img">*/}
          {/*                                                                  <img*/}
          {/*                                                                      src="/assets/img/pages/home-countryside-hotel.jpg"*/}
          {/*                                                                      alt="Home Six"*/}
          {/*                                                                  />*/}
          {/*                                                                  <div className="btn-wrap">*/}
          {/*                                                                      <a*/}
          {/*                                                                          target="_blank"*/}
          {/*                                                                          href="home-countryside-hotel.html"*/}
          {/*                                                                          className="th-btn"*/}
          {/*                                                                      >*/}
          {/*                                                                          View Demo*/}
          {/*                                                                      </a>*/}
          {/*                                                                  </div>*/}
          {/*                                                              </div>*/}
          {/*                                                              <h3 className="mega-menu-title">*/}
          {/*                                                                  <a href="home-countryside-hotel.html">*/}
          {/*                                                                      <span>07.</span>Home Countryside Hotel*/}
          {/*                                                                  </a>*/}
          {/*                                                              </h3>*/}
          {/*                                                          </div>*/}
          {/*                                                      </div>*/}
          {/*                                                      <div className="col-lg-3">*/}
          {/*                                                          <div className="mega-menu-box">*/}
          {/*                                                              <div className="mega-menu-img">*/}
          {/*                                                                  <img*/}
          {/*                                                                      src="/assets/img/pages/home-forest.jpg"*/}
          {/*                                                                      alt="Home Six"*/}
          {/*                                                                  />*/}
          {/*                                                                  <div className="btn-wrap">*/}
          {/*                                                                      <a*/}
          {/*                                                                          target="_blank"*/}
          {/*                                                                          href="home-forest.html"*/}
          {/*                                                                          className="th-btn"*/}
          {/*                                                                      >*/}
          {/*                                                                          View Demo*/}
          {/*                                                                      </a>*/}
          {/*                                                                  </div>*/}
          {/*                                                              </div>*/}
          {/*                                                              <h3 className="mega-menu-title">*/}
          {/*                                                                  <a href="home-forest.html">*/}
          {/*                                                                      <span>08.</span>Home Forest*/}
          {/*                                                                  </a>*/}
          {/*                                                              </h3>*/}
          {/*                                                          </div>*/}
          {/*                                                      </div>*/}
          {/*                                                  </div>*/}
          {/*                                              </div>*/}
          {/*                                          </li>*/}
          {/*                                      </ul>*/}
          {/*                                  </li>*/}
          {/*                                  <li>*/}
          {/*                                      <a href="about.html">About Us</a>*/}
          {/*                                  </li>*/}
          {/*                                  <li className="menu-item-has-children">*/}
          {/*                                      <a href="#">Destination</a>*/}
          {/*                                      <ul className="sub-menu">*/}
          {/*                                          <li>*/}
          {/*                                              <a href="destination.html">Destination</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="destination-details.html">*/}
          {/*                                                  Destination Details*/}
          {/*                                              </a>*/}
          {/*                                          </li>*/}
          {/*                                      </ul>*/}
          {/*                                  </li>*/}
          {/*                                  <li className="menu-item-has-children">*/}
          {/*                                      <a href="#">Service</a>*/}
          {/*                                      <ul className="sub-menu">*/}
          {/*                                          <li>*/}
          {/*                                              <a href="service.html">Services</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="service-details.html">Service Details</a>*/}
          {/*                                          </li>*/}
          {/*                                      </ul>*/}
          {/*                                  </li>*/}
          {/*                                  <li className="menu-item-has-children">*/}
          {/*                                      <a href="#">Activities</a>*/}
          {/*                                      <ul className="sub-menu">*/}
          {/*                                          <li>*/}
          {/*                                              <a href="activities.html">activities</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="activities-details.html">activities Details</a>*/}
          {/*                                          </li>*/}
          {/*                                      </ul>*/}
          {/*                                  </li>*/}
          {/*                                  <li className="menu-item-has-children">*/}
          {/*                                      <a href="#">Pages</a>*/}
          {/*                                      <ul className="sub-menu">*/}
          {/*                                          <li className="menu-item-has-children">*/}
          {/*                                              <a href="#">Shop</a>*/}
          {/*                                              <ul className="sub-menu">*/}
          {/*                                                  <li>*/}
          {/*                                                      <a href="shop.html">Shop</a>*/}
          {/*                                                  </li>*/}
          {/*                                                  <li>*/}
          {/*                                                      <a href="shop-details.html">Shop Details</a>*/}
          {/*                                                  </li>*/}
          {/*                                                  <li>*/}
          {/*                                                      <a href="cart.html">Cart Page</a>*/}
          {/*                                                  </li>*/}
          {/*                                                  <li>*/}
          {/*                                                      <a href="checkout.html">Checkout</a>*/}
          {/*                                                  </li>*/}
          {/*                                                  <li>*/}
          {/*                                                      <a href="wishlist.html">Wishlist</a>*/}
          {/*                                                  </li>*/}
          {/*                                              </ul>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="gallery.html">Gallery</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="tour.html">Our Tour</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="tour-details.html">Tour Details</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="resort.html">Resort page</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="resort-details.html">Resort Details</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="tour-details.html">Tour Details</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="tour-guide.html">Tour Guider</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="tour-guider-details.html">*/}
          {/*                                                  Tour Guider Details*/}
          {/*                                              </a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="faq.html">Faq Page</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="price.html">Price Package</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="error.html">Error Page</a>*/}
          {/*                                          </li>*/}
          {/*                                      </ul>*/}
          {/*                                  </li>*/}
          {/*                                  <li className="menu-item-has-children">*/}
          {/*                                      <a href="#">Blog</a>*/}
          {/*                                      <ul className="sub-menu">*/}
          {/*                                          <li>*/}
          {/*                                              <a href="blog.html">Blog</a>*/}
          {/*                                          </li>*/}
          {/*                                          <li>*/}
          {/*                                              <a href="blog-details.html">Blog Details</a>*/}
          {/*                                          </li>*/}
          {/*                                      </ul>*/}
          {/*                                  </li>*/}
          {/*                                  <li>*/}
          {/*                                      <a href="contact.html">Contact us</a>*/}
          {/*                                  </li>*/}
          {/*                              </ul>*/}
          {/*                          </nav>*/}
          {/*                          <button*/}
          {/*                              type="button"*/}
          {/*                              className="th-menu-toggle d-block d-xl-none"*/}
          {/*                          >*/}
          {/*                              <i className="far fa-bars" />*/}
          {/*                          </button>*/}
          {/*                      </div>*/}
          {/*                      <div className="col-auto d-none d-xl-block">*/}
          {/*                          <div className="header-button">*/}
          {/*                              <a href="contact.html" className="th-btn style3 th-icon">*/}
          {/*                                  Book Now*/}
          {/*                              </a>*/}
          {/*                          </div>*/}
          {/*                      </div>*/}
          {/*                  </div>*/}
          {/*              </div>*/}
          {/*              <div className="logo-bg" data-mask-src="assets/img/logo_bg_mask.png" />*/}
          {/*          </div>*/}
          {/*      </div>*/}
          {/*  </header>*/}
            <Header/>
            <div
                className="breadcumb-wrapper"
                data-bg-src="assets/img/bg/breadcumb-bg.jpg"
            >
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Contact Us</h1>
                        <ul className="breadcumb-menu">
                            <li>
                                <a href="home-travel.html">Home</a>
                            </li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="space">
                <div className="container">
                    <div className="title-area text-center">
                        <span className="sub-title">Get In Touch</span>
                        <h2 className="sec-title">Our Contact Information</h2>
                    </div>
                    <div className="row gy-4 justify-content-center">
                        <div className="col-xl-4 col-lg-6">
                            <div className="about-contact-grid style2">
                                <div className="about-contact-icon">
                                    <img
                                        src="/assets/img/icon/location-dot2.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="about-contact-details">
                                    <h6 className="box-title">Our Address</h6>
                                    <p className="about-contact-details-text">
                                        71-75 Shelton Street, Covent Garden
                                    </p>
                                    <p className="about-contact-details-text">
                                        London, United Kingdom, WC2H 9JQ
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6">
                            <div className="about-contact-grid">
                                <div className="about-contact-icon">
                                    <img src="/assets/img/icon/call.svg" alt="" />
                                </div>
                                <div className="about-contact-details">
                                    <h6 className="box-title">Phone Number</h6>
                                    <p className="about-contact-details-text">
                                        <a href="tel:01234567890">+91 124-4956534</a>
                                    </p>
                                    <p className="about-contact-details-text">
                                        <a href="tel:01234567890">+91 124-4956534</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6">
                            <div className="about-contact-grid">
                                <div className="about-contact-icon">
                                    <img src="/assets/img/icon/mail.svg" alt="" />
                                </div>
                                <div className="about-contact-details">
                                    <h6 className="box-title">Email Address</h6>
                                    <p className="about-contact-details-text">
                                        <a href="mailto:mailinfo00@tourm.com">contact@farefuels.com</a>
                                    </p>
                                    <p className="about-contact-details-text">
                                        <a href="mailto:support24@tourm.com">contact@farefuels.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="space-extra2-top space-extra2-bottom"
                data-bg-src="assets/img/bg/video_bg_1.jpg"
            >
                <div className="container">
                    <div className="row flex-row-reverse justify-content-center align-items-center">
                        <div className="col-lg-6">
                            <div className="video-box1">
                                <a
                                    href="https://www.youtube.com/watch?v=cQfIUPw72Dk"
                                    className="play-btn style2 popup-video"
                                >
                                    <i className="fa-sharp fa-solid fa-play" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <form
                                    action="https://html.themeholy.com/tourm/demo/mail.php"
                                    method="POST"
                                    className="contact-form style2 ajax-contact"
                                >
                                    <h3 className="sec-title mb-30 text-capitalize">Book a tour</h3>
                                    <div className="row">
                                        <div className="col-12 form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                id="name3"
                                                placeholder="First Name"
                                            />{" "}
                                            <img src="/assets/img/icon/user.svg" alt="" />
                                        </div>
                                        <div className="col-12 form-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email3"
                                                id="email3"
                                                placeholder="Your Mail"
                                            />{" "}
                                            <img src="/assets/img/icon/mail.svg" alt="" />
                                        </div>
                                        <div className="form-group col-12">
                                            <select
                                                name="subject"
                                                id="subject"
                                                className="form-select nice-select"
                                            >
                                                <option
                                                    value="Select Tour Type"
                                                    selected="selected"
                                                    disabled="disabled"
                                                >
                                                    Select Tour Type
                                                </option>
                                                <option value="Africa Adventure">Africa Adventure</option>
                                                <option value="Africa Wild">Africa Wild</option>
                                                <option value="Asia">Asia</option>
                                                <option value="Scandinavia">Scandinavia</option>
                                                <option value="Western Europe">Western Europe</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-12">
                  <textarea
                      name="message"
                      id="message"
                      cols={30}
                      rows={3}
                      className="form-control"
                      placeholder="Your Message"
                      defaultValue={""}
                  />{" "}
                                            <img src="/assets/img/icon/chat.svg" alt="" />
                                        </div>
                                        <div className="form-btn col-12 mt-24">
                                            <button type="submit" className="th-btn style3">
                                                Send message{" "}
                                                <img src="/assets/img/icon/plane.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container-fluid">
                    <div className="contact-map style2">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.7310056272386!2d89.2286059153658!3d24.00527418490799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fe9b97badc6151%3A0x30b048c9fb2129bc!2sAngfuztheme!5e0!3m2!1sen!2sbd!4v1651028958211!5m2!1sen!2sbd"
                            allowFullScreen=""
                            loading="lazy"
                        />
                        <div className="contact-icon">
                            <img src="/assets/img/icon/location-dot3.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/*<footer className="footer-wrapper bg-title footer-layout2">*/}
            {/*    <div className="widget-area">*/}
            {/*        <div className="container">*/}
            {/*            <div className="newsletter-area">*/}
            {/*                <div className="newsletter-top">*/}
            {/*                    <div className="row gy-4 align-items-center">*/}
            {/*                        <div className="col-lg-5">*/}
            {/*                            <h2 className="newsletter-title text-white text-capitalize mb-0">*/}
            {/*                                get updated the latest newsletter*/}
            {/*                            </h2>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-lg-7">*/}
            {/*                            <form className="newsletter-form style2">*/}
            {/*                                <input*/}
            {/*                                    className="form-control"*/}
            {/*                                    type="email"*/}
            {/*                                    placeholder="Enter Email"*/}
            {/*                                    required=""*/}
            {/*                                />{" "}*/}
            {/*                                <button type="submit" className="th-btn style1">*/}
            {/*                                    Subscribe Now{" "}*/}
            {/*                                    <img src="/assets/img/icon/plane2.svg" alt="" />*/}
            {/*                                </button>*/}
            {/*                            </form>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="row justify-content-between">*/}
            {/*                <div className="col-md-6 col-xl-3">*/}
            {/*                    <div className="widget footer-widget">*/}
            {/*                        <div className="th-widget-about">*/}
            {/*                            <div className="about-logo">*/}
            {/*                                <a href="home-travel.html">*/}
            {/*                                    <img*/}
            {/*                                        src="/assets/img/logo-white.svg"*/}
            {/*                                        alt="Tourm"*/}
            {/*                                    />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <p className="about-text">*/}
            {/*                                Rapidiously myocardinate cross-platform intellectual capital*/}
            {/*                                model. Appropriately create interactive infrastructures*/}
            {/*                            </p>*/}
            {/*                            <div className="th-social">*/}
            {/*                                <a href="https://www.facebook.com/">*/}
            {/*                                    <i className="fab fa-facebook-f" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://www.twitter.com/">*/}
            {/*                                    <i className="fab fa-twitter" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://www.linkedin.com/">*/}
            {/*                                    <i className="fab fa-linkedin-in" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://www.whatsapp.com/">*/}
            {/*                                    <i className="fab fa-whatsapp" />*/}
            {/*                                </a>{" "}*/}
            {/*                                <a href="https://instagram.com/">*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 col-xl-auto">*/}
            {/*                    <div className="widget widget_nav_menu footer-widget">*/}
            {/*                        <h3 className="widget_title">Quick Links</h3>*/}
            {/*                        <div className="menu-all-pages-container">*/}
            {/*                            <ul className="menu">*/}
            {/*                                <li>*/}
            {/*                                    <a href="index.html">Home</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="about.html">About us</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="service.html">Our Service</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="contact.html">Terms of Service</a>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <a href="contact.html">Tour Booking Now</a>*/}
            {/*                                </li>*/}
            {/*                            </ul>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 col-xl-auto">*/}
            {/*                    <div className="widget footer-widget">*/}
            {/*                        <h3 className="widget_title">Get In Touch</h3>*/}
            {/*                        <div className="th-widget-contact">*/}
            {/*                            <div className="info-box_text">*/}
            {/*                                <div className="icon">*/}
            {/*                                    <img*/}
            {/*                                        src="/assets/img/icon/phone.svg"*/}
            {/*                                        alt="img"*/}
            {/*                                    />*/}
            {/*                                </div>*/}
            {/*                                <div className="details">*/}
            {/*                                    <p>*/}
            {/*                                        <a href="tel:+01234567890" className="info-box_link">*/}
            {/*                                            +01 234 567 890*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                    <p>*/}
            {/*                                        <a href="tel:+09876543210" className="info-box_link">*/}
            {/*                                            +09 876 543 210*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="info-box_text">*/}
            {/*                                <div className="icon">*/}
            {/*                                    <img*/}
            {/*                                        src="/assets/img/icon/envelope.svg"*/}
            {/*                                        alt="img"*/}
            {/*                                    />*/}
            {/*                                </div>*/}
            {/*                                <div className="details">*/}
            {/*                                    <p>*/}
            {/*                                        <a*/}
            {/*                                            href="mailto:mailinfo00@tourm.com"*/}
            {/*                                            className="info-box_link"*/}
            {/*                                        >*/}
            {/*                                            mailinfo00@tourm.com*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                    <p>*/}
            {/*                                        <a*/}
            {/*                                            href="mailto:support24@tourm.com"*/}
            {/*                                            className="info-box_link"*/}
            {/*                                        >*/}
            {/*                                            support24@tourm.com*/}
            {/*                                        </a>*/}
            {/*                                    </p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="info-box_text">*/}
            {/*                                <div className="icon">*/}
            {/*                                    <img*/}
            {/*                                        src="/assets/img/icon/location-dot.svg"*/}
            {/*                                        alt="img"*/}
            {/*                                    />*/}
            {/*                                </div>*/}
            {/*                                <div className="details">*/}
            {/*                                    <p>789 Inner Lane, Holy park, California, USA</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 col-xl-auto">*/}
            {/*                    <div className="widget footer-widget">*/}
            {/*                        <h3 className="widget_title">Instagram Post</h3>*/}
            {/*                        <div className="sidebar-gallery">*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_1.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_2.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_3.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_4.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_5.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div className="gallery-thumb">*/}
            {/*                                <img*/}
            {/*                                    src="/assets/img/widget/gallery_1_6.jpg"*/}
            {/*                                    alt="Gallery Image"*/}
            {/*                                />{" "}*/}
            {/*                                <a*/}
            {/*                                    target="_blank"*/}
            {/*                                    href="https://www.instagram.com/"*/}
            {/*                                    className="gallery-btn"*/}
            {/*                                >*/}
            {/*                                    <i className="fab fa-instagram" />*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="copyright-wrap">*/}
            {/*        <div className="container">*/}
            {/*            <div className="row justify-content-between align-items-center">*/}
            {/*                <div className="col-md-6">*/}
            {/*                    <p className="copyright-text">*/}
            {/*                        Copyright 2024 <a href="home-travel.html">Tourm</a>. All Rights*/}
            {/*                        Reserved.*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*                <div className="col-md-6 text-end d-none d-md-block">*/}
            {/*                    <div className="footer-card">*/}
            {/*                        <span className="title">We Accept</span>{" "}*/}
            {/*                        <img src="/assets/img/shape/cards.png" alt="" />*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*        className="shape-mockup movingX d-none d-xxl-block"*/}
            {/*        data-top="24%"*/}
            {/*        data-left="5%"*/}
            {/*    >*/}
            {/*        <img src="/assets/img/shape/shape_8.png" alt="shape" />*/}
            {/*    </div>*/}
            {/*</footer*/}
            <Footer/>
            <div className="scroll-top">
                <svg
                    className="progress-circle svg-content"
                    width="100%"
                    height="100%"
                    viewBox="-1 -1 102 102"
                >
                    <path
                        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                        style={{
                            transition: "stroke-dashoffset 10ms linear 0s",
                            strokeDasharray: "307.919, 307.919",
                            strokeDashoffset: "307.919"
                        }}
                    />
                </svg>
            </div>
            <div id="login-form" className="popup-login-register mfp-hide">
                <ul className="nav" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-menu"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="false"
                        >
                            Login
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-menu active"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="true"
                        >
                            Register
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                    >
                        <h3 className="box-title mb-30">Sign in to your account</h3>
                        <div className="th-login-form">
                            <form
                                action="https://html.themeholy.com/tourm/demo/mail.php"
                                method="POST"
                                className="login-form ajax-contact"
                            >
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label>Username or email</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            required="required"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label>Password</label>{" "}
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="pasword"
                                            id="pasword"
                                            required="required"
                                        />
                                    </div>
                                    <div className="form-btn mb-20 col-12">
                                        <button className="th-btn btn-fw th-radius2">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                                <div id="forgot_url">
                                    <a href="my-account.html">Forgot password?</a>
                                </div>
                                <p className="form-messages mb-0 mt-3" />
                            </form>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade active show"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                    >
                        <h3 className="th-form-title mb-30">Sign in to your account</h3>
                        <form
                            action="https://html.themeholy.com/tourm/demo/mail.php"
                            method="POST"
                            className="login-form ajax-contact"
                        >
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Username*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="usename"
                                        id="usename"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label>First name*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="firstname"
                                        id="firstname"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label>Last name*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="lastname"
                                        id="lastname"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="new_email">Your email*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="new_email"
                                        id="new_email"
                                        required="required"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="new_email_confirm">Confirm email*</label>{" "}
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="new_email_confirm"
                                        id="new_email_confirm"
                                        required="required"
                                    />
                                </div>
                                <div className="statement">
              <span className="register-notes">
                A password will be emailed to you.
              </span>
                                </div>
                                <div className="form-btn mt-20 col-12">
                                    <button className="th-btn btn-fw th-radius2">Sign up</button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3" />
                        </form>
                    </div>
                </div>
            </div>
            {/* Mirrored from html.themeholy.com/tourm/demo/contact.html by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 20 Sep 2024 17:14:22 GMT */}
        </>

    )
}


export default Contact;